<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteContact"
    />

    <v-dialog
      width="1200"
      v-model="showContactForm"
      @keydown.esc="showContactForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showContactForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <ContactForm
          :showCancel="true"
          :contact="contact"
          :title="formTitle"
          @contact-updated="handleContactUpdated"
          @on-cancel="showContactForm = false"
        />
      </v-card>
    </v-dialog>

    <v-row align="center">
      <v-col md="4" cols="12">
        <SearchInput @search="handleSearchInput" />
      </v-col>
      <v-col md="4" cols="12">
        <v-switch
          :value="accessToContact"
          :loading="loadingConfiguration"
          :disabled="loadingConfiguration"
          @click="updateAccessToSingup(!accessToContact)"
          label="Habilitado"
          class="mb-5"
          inset
        ></v-switch>
      </v-col>
    </v-row>

    <p class="caption pasive--text text-right">{{ totalContacts }} registros</p>

    <v-data-table
      :headers="headers ? headers : defaultHeaders"
      :items="contacts"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      loading-text="Cargando..."
      :no-data-text="
        filter === ''
          ? 'No hay pacientes registrados'
          : 'Paciente no encontrado'
      "
      item-key="id"
      item-class="text-center"
      :class="clickable ? 'row-pointer' : ''"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalContacts"
    >
      <template slot="item.createdAt" scope="props">
        {{ formatDateAndTime(props.item.createdAt) }}
      </template>
      <template slot="item.updatedAt" scope="props">
        {{ formatDateAndTime(props.item.updatedAt) }}
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                icon
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click.stop="handleClickEdit(props.item)"
              >
                <v-icon small> mdi-square-edit-outline </v-icon>
              </v-btn>
            </template>
            <span class="caption">Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                @click.stop="showWarning(props.item)"
              >
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span class="caption">Eliminar</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import WarningDialog from "../WarningDialog.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import SearchInput from "../SearchInput.vue";
import moment from "moment";

export default {
  name: "ContactList",
  props: ["clickable", "headers"],
  components: {
    ContactForm,
    WarningDialog,
    SearchInput,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      defaultHeaders: [
        { text: "Nombre", value: "name" },
        { text: "Teléfono", value: "phone" },
        { text: "Correo electrónico", value: "email" },
        { text: "País", value: "country" },
        { text: "Creado", value: "createdAt" },
        { text: "Última actualización", value: "updatedAt" },
        { text: "Acciones", value: "action", align: "center" },
      ],
      filter: "",
      loading: false,
      deleting: false,
      showContactForm: false,
      formTitle: "Crear",
      contact: null,
      options: {},
      totalContacts: 0,
      accessToContact: false,
    };
  },
  computed: {
    ...mapGetters([
      "contacts",
      "currentUser",
      "configuration",
      "loadingConfiguration",
    ]),
  },
  methods: {
    ...mapActions(["fetchContacts"]),
    ...mapMutations(["setAlert", "removeContact", "setConfiguration"]),
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    handleContactUpdated() {
      this.showContactForm = false;
    },
    handleClickEdit(item) {
      this.contact = item;
      this.formTitle = "Actualizar";
      this.showContactForm = true;
    },
    showWarning(item) {
      this.contact = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar ${this.contact.name}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async updateAccessToSingup(value) {
      try {
        await axios.put(`/api/configuration`, {
          ...this.configuration,
          singup: value,
        });
        this.setConfiguration({
          ...this.configuration,
          singup: value,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
    },
    async deleteContact() {
      this.deleting = true;
      try {
        const res = await axios.delete(`/api/contact/${this.contact.id}`);
        this.warning = false;
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removeContact(this.Contact);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    handleSearchInput(term) {
      this.getData({ term });
    },
    async getData(params) {
      this.loading = true;

      const page = params?.page || 1;
      const pageSize = params?.pageSize || 10;

      let orderBy = [
        {
          column: "contact.name",
          direction: "ASC",
        },
      ];

      if (!params?.term) {
        orderBy.unshift({
          column: "contact.updatedAt",
          direction: "DESC",
        });
      }

      const data = await this.fetchContacts({
        term: params?.term,
        page,
        pageSize,
        orderBy,
      });

      this.totalContacts = data?.count || 0;
      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    configuration: {
      handler(value) {
        this.accessToContact = value?.singup;
      },
      deep: true,
    },
    options: {
      handler(value) {
        this.getData({
          page: value.page || 1,
          pageSize: value.itemsPerPage || 15,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.accessToContact = this.configuration?.singup || false;
  },
};
</script>
