<template>
  <div>
    <v-form
      v-model="validation"
      ref="form"
      @submit.prevent="title === 'Crear' ? createContact() : editContact()"
    >
      <v-container>
        <Title :title="title" class="mb-8" />
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.name"
              type="text"
              label="Nombre *"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.phone"
              type="text"
              label="Teléfono *"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.email"
              type="text"
              label="Correo electrónico *"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.country"
              type="text"
              label="País *"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        depressed
        small
        color="primary"
        class="pa-5"
        type="submit"
        :disabled="!validation"
        :loading="loading"
      >
        {{ title }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import Title from "../Title.vue";

export default {
  name: "ContactForm",
  components: {
    Title
  },
  props: ["title", "showCancel", "contact"],
  data() {
    return {
      form: {
        name: "",
        phone: "",
        country: "",
        email: "",
      },
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 100) || "Debe tener menos de 100 caracteres",
        ],
      },
      validation: true,
      loading: false,
      pickerDate: "1995-01-01",
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapMutations(["pushContact", "setAlert", "updateContact"]),
    async createContact() {
      this.loading = true;
      try {
        const res = await axios.post("/api/contact", {
          ...this.form,
        });
        const { item, message } = res.data;
        this.pushContact(item);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$refs.form.reset();
        this.$emit("item-created", item);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async editContact() {
      this.loading = true;
      try {
        const payload = {
          id: this.contact.id,
          ...this.form,
        };
        const res = await axios.put(`/api/contact/${this.contact.id}`, payload);
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.updateContact(payload);
        this.$emit("item-updated", payload);
        this.$emit("on-cancel");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("on-cancel");
    },
  },
  watch: {
    contact: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          Object.assign(this.form, newVal);
        }

        if (this.title === "Crear") {
          this.$refs.form?.reset();
        }
      },
    },
    "form.phone": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.form.phone = newVal.replace(/[^\d-]/g, "");
        }
      },
    },
  },
};
</script>
