var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WarningDialog',{ref:"WarningDialogRef",attrs:{"loading":_vm.deleting},on:{"action":_vm.deleteContact}}),_c('v-dialog',{attrs:{"width":"1200","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showContactForm = false}},model:{value:(_vm.showContactForm),callback:function ($$v) {_vm.showContactForm=$$v},expression:"showContactForm"}},[_c('v-card',{staticClass:"pa-sm-10 pa-5"},[_c('div',{staticClass:"text-right"},[_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContactForm = false}}},[_vm._v(" mdi-close ")])],1),_c('ContactForm',{attrs:{"showCancel":true,"contact":_vm.contact,"title":_vm.formTitle},on:{"contact-updated":_vm.handleContactUpdated,"on-cancel":function($event){_vm.showContactForm = false}}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('SearchInput',{on:{"search":_vm.handleSearchInput}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-switch',{staticClass:"mb-5",attrs:{"value":_vm.accessToContact,"loading":_vm.loadingConfiguration,"disabled":_vm.loadingConfiguration,"label":"Habilitado","inset":""},on:{"click":function($event){return _vm.updateAccessToSingup(!_vm.accessToContact)}}})],1)],1),_c('p',{staticClass:"caption pasive--text text-right"},[_vm._v(_vm._s(_vm.totalContacts)+" registros")]),_c('v-data-table',{class:_vm.clickable ? 'row-pointer' : '',attrs:{"headers":_vm.headers ? _vm.headers : _vm.defaultHeaders,"items":_vm.contacts,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Cargando...","no-data-text":_vm.filter === ''
        ? 'No hay pacientes registrados'
        : 'Paciente no encontrado',"item-key":"id","item-class":"text-center","disable-sort":"","footer-props":{
      itemsPerPageText: 'Resultados por pág.',
      itemsPerPageAllText: 'Todos',
      pageText: '',
      showCurrentPage: true,
      itemsPerPageOptions: [15, 30, 60],
    },"options":_vm.options,"server-items-length":_vm.totalContacts},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(props.item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(props.item.updatedAt))+" ")]}},{key:"item.action",fn:function(props){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"text":"","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClickEdit(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"text":"","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showWarning(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Eliminar")])])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }